<template>
  <div>删除权限资源</div>
</template>

<script>
export default {

}
</script>

<style>

</style>